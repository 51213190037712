<template>
    <div class="flex" v-loading="$waiting.is('loading')">
        <div class="w-full xl:w-1/2">
            <div class="sticky top-0 z-10">
                <Filters ref="filtersComponent" @change="filtersChanged" />
            </div>

            <div class="flex bg-white sticky top-[61px] z-10 p-2 border-b mb-4">
                <p class="w-[100px]">Verifikat</p>
                <p class="w-[200px]">Bokföringsdatum</p>
                <p class="w-[200px]">Registreringsdatum</p>
                <p class="flex-1">Kommentar</p>
            </div>

            <div v-for="table in itemsData" :key="table.voucherId" class="mb-2 py-10 border-b card-tail">
                <div class="flex mb-4 pb-3 text-sm border-b">
                    <p class="w-[100px]">
                        {{ table.number }}
                    </p>
                    <p class="w-[200px]">
                        {{ formatDate(table.date) }}
                    </p>
                    <p class="w-[200px]">
                        {{ formatDate(table.registerDate) }}
                    </p>
                    <p class="flex-1 wra">
                        {{ table.text }}
                    </p>
                    <div>
                        <el-button v-if="table.hasDocument" type="primary" class="p-[2px]">
                            <i class="fa-solid fa-paperclip" />
                        </el-button>
                        <el-button type="primary" class="p-[2px] ml-1" @click="editVoucher(table.voucherId)">
                            <i class="fa-solid fa-pen" />
                        </el-button>
                    </div>
                </div>

                <div class="flex bg-white sticky top-[102px] z-0">
                    <p class="font-semibold rounded w-[350px]">Konto</p>
                    <p class="font-semibold rounded w-[100px] text-right">Debet</p>
                    <p class="font-semibold rounded w-[100px] text-right mr-5">Kredit</p>
                    <p class="font-semibold rounded flex-1">Text</p>
                    <!-- <p class="font-semibold rounded flex-1">Kosnadsställe</p> -->
                </div>

                <div v-for="item in table.transactions" :key="item.transactionId" class="flex text-sm">
                    <p class="w-[45px]">{{ item.account }}</p>
                    <p class="w-[305px]">{{ item.accountName }}</p>
                    <p class="w-[100px] text-right">
                        {{ item.debit | swedishNumberFormat }}
                    </p>
                    <p class="w-[100px] text-right mr-2">
                        {{ item.credit | swedishNumberFormat }}
                    </p>
                    <p class="w-[18px]">
                        <span v-if="item.correction" class="flex items-center justify-center bg-company-blue w-4 h-4 mt-[2px] text-xs rounded-full text-white">K</span>
                    </p>
                    <p class="flex-1">
                        {{ item.text }}
                    </p>
                    <!-- <p>
                        {{ item.ks }}
                    </p> -->
                </div>
            </div>

            <div class="card-tail">
                <Pagination ref="paginationComponent" :total-amount="totalItemsNumber" @change="updateUrlAndGetAll" />
            </div>
        </div>
        <div class="w-full xl:w-1/2 ml-3">
            <div class="sticky top-2">
                <VoucherEdit :voucher-id="selectedVoucherId" :client-id="clientId" @cancel="removeVoucherSelection" @refresh="getAll" @filesChanged="updateFilesCount" :query="query" />
            </div>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "../lopande.api";

export default {
    components: {
        Pagination: () => import("../components/Pagination.vue"),
        Filters: () => import("./components/Filters.vue"),
        VoucherEdit: () => import("./components/VoucherEdit.vue"),
    },

    data() {
        return {
            selectedVoucherId: null,
            itemsData: [],
            totalItemsNumber: 0,
            pageNumber: 1,
            pageSize: 15,
        };
    },

    props: {
        query: {
            type: Object,
        },
        clientId: {
            type: String,
            default: "",
        },
        selectedDate: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        clientId() {
            this.updateUrlAndGetAll();
            this.getAll();
        },

        async selectedDate(newValue, oldValue) {
            if (newValue[0] === oldValue[0]) return;
            this.$refs.paginationComponent.updateUrl(1);
            if (newValue[0] === oldValue[0]) return;
            await this.$refs.filtersComponent.reset();
            this.getAll();
        },

        query() {
            this.selectedVoucherId = null;
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        updateUrlAndGetAll() {
            // this.$refs.paginationComponent.updateUrl(1);
            const query = queryString.parse(window.location.search, { sort: false });
            this.$router.replace({ query }).catch(() => {});
            this.getAll();
        },

        async filtersChanged() {
            const query = queryString.parse(window.location.search);
            query.pageNumber = 1;
            await this.$router.replace({ query }).catch(() => {});
            this.$refs.paginationComponent.assignInitValues();
            this.getAll();
        },

        async getAll() {
            if (!this.selectedDate.length) return;

            this.$waiting.start("loading");
            const query = queryString.parse(window.location.search);

            const response = await Api.getVouchers({
                clientId: this.clientId,
                startDate: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                endDate: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                number: query.filterNumber,
                date: query.filterDate,
                registerDate: query.filterRegistrationDate,
                text: query.filterText,
                amount: query.filterAmount,
                account: query.filterAccount,
                pagenumber: query.pageNumber || this.pagenumber,
                pageSize: query.pageSize || this.pageSize,
            });
            this.itemsData = response.data;
            this.totalItemsNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        editVoucher(voucherId) {
            this.selectedVoucherId = voucherId;
        },

        removeVoucherSelection() {
            this.selectedVoucherId = null;
        },

        updateFilesCount(count) {
            const index = this.itemsData.findIndex(item => item.voucherId === this.selectedVoucherId);
            this.itemsData[index].hasDocument = !!count;
        },
    },
};
</script>
<style scoped>
table {
    font-size: 13px;
}

table:first-of-type thead th {
    font-weight: 700;
}

table th {
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 5px;
}

table td {
    /* padding: 5px 0; */
}

table tr:last-child td {
    padding-bottom: 15px;
}
</style>
